<template>
  <div id="order-view" class="order-show py-5 pb-lg-6" >
    <div class="mb-3">
      <button @click="$router.go(-1)" type="button" class="btn btn-light"> <i class="mdi mdi-arrow-left"></i> Back</button> 
    </div>
    <div v-if="isLoading">
        <div class="mb-3">
            <b-skeleton animation="wave" height="70px" width="80%"></b-skeleton>
        </div>
        <div class="mb-3">
            <b-skeleton animation="wave" height="50px" width="60%"></b-skeleton>
        </div>
        <div class="mb-5">
            <b-skeleton animation="wave" height="40px" width="50%"></b-skeleton>
        </div>
        <div >
            <b-skeleton animation="wave" height="400px" width="100%"></b-skeleton>
        </div>
    </div>
    <section v-else class="">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-12">
              <div v-if="order.status == 'draft'" >
                <b-alert :show="true"  variant="warning">
                  <h5 class="alert-heading"><i class="mdi mdi-check-all mr-2"></i>Saved as draft!</h5>
                  <p class="mb-0">
                    <span class="mr-2">This order was saved as draft. Proceed to make payment so that this order can be processed</span>
                  </p>
                  <hr class="my-2">
                  <div class="d-flex justify-content-end">
                    <a class="btn btn-outline-primary mr-2" :href="absoluteUrl(`/projects/${order.id}/payment`)">Pay Now!</a>
                    <a class="btn btn-outline-danger" @click.prevent="deleteDraft()" href="#">Delete Draft!</a>
                  </div>
                </b-alert>
              </div>
              <div v-else>
                <div class="mb-4">
                  <h3 class="mb-2 font-weight-bold">{{product.name}}</h3>
                  <p>Status: <strong class="text-uppercase mr-2">{{order.status}}</strong> Amount: <strong class="text-uppercase">${{order.amount | money_format}}</strong> </p>
                  <p>Purchase was made by <strong>{{activeUser.first_name}}.</strong> <span class="mr-2">Order Number: <strong>#{{order.number}}</strong></span> 
                  <a class="text-success" :href="absoluteUrl(`/${product.slug}`)">view product</a></p>
                </div>
                <div class="mb-4 d-flex">
                  <div class="soft-bread w-auto overflow-hidden border shadow-sm">
                    <span class="soft-bread-item border-right"
                      :class="{'bg-success text-white': order.stage == 'design'}"><span style="z-index: 2;">DESIGN</span></span>
                      <span class="soft-bread-item border-right"
                      :class="{'bg-success text-white': order.stage == 'finalist'}"><span style="z-index: 2;">FINALIST</span></span>
                    <span class="soft-bread-item"
                    :class="{'bg-success text-white': order.stage == 'finished'}"><span style="z-index: 2;">FILES</span></span>
                  </div>
                </div>
                <div class="mb-5">
                  <div class="font-18 d-flex">
                    <i class="fa fa-clock mr-2"></i>
                    <span class="font-16"> Maximum of <strong> 7 days</strong> is expected to choose finalist </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12  mb-4 mb-lg-5">
              <b-tabs nav-class="nav-tabs order-show-nav brand-nav-design nav-bordered">
                <b-tab :active="activeTab === 1">
                  <template v-slot:title>
                    <i class="mdi mdi-book-outline mr-1"></i>
                    <span class="nav-label">Brief</span>
                  </template>
                  <div class="card">
                    <div class="card-body px-lg-6">
                      <order-brief />
                    </div>
                  </div>
                </b-tab>
                <b-tab :active="activeTab === 2">
                  <template v-slot:title>
                    <i class="mdi mdi-file-image mr-1"></i>
                    <span class="nav-label">Designs</span>
                  </template>
                  <div class="card">
                    <div class="card-body px-lg-6">
                      <order-designs />
                    </div>
                  </div>
                </b-tab>
                <b-tab :active="activeTab === 3" :lazy="activeTab != 2">
                  <template v-slot:title>
                    <i class="mdi mdi-cloud-download mr-1"></i>
                    <span class="nav-label">Downloads</span>
                  </template>
                  <div class="card">
                    <div class="card-body px-lg-6">
                      <order-downloads />
                    </div>
                  </div>
                </b-tab>
                  <b-tab :active="activeTab === 4">
                    <template v-slot:title>
                      <i class="mdi mdi-book-open-variant mr-1"></i>
                      <span class="nav-label">Notes</span> 
                    </template>
                    <div class="card">
                      <div class="card-body px-lg-6">
                        <order-notes />
                      </div>
                    </div>
                  </b-tab>
              </b-tabs>
            </div>
            <div class="col-12 col-md-10 col-lg-9">
              <order-extra-service
                  class="mb-3"
                  v-if="['active','delivered','revision'].includes(order.status)"
                  :orderId="order.id"
                  :product="order.product" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import OrderBrief from "@/components/order/OrderBrief.vue"
import OrderDownloads from "@/components/order/OrderDownloads.vue"
import OrderDesigns from "@/components/order/OrderDesigns.vue"
import OrderNotes from "@/components/order/OrderNotes.vue"
import OrderExtraService from '@/components/order/OrderExtraService.vue'

export default {
  name: "order-show",
  components: {
    OrderBrief,
    OrderDownloads,
    OrderDesigns,
    OrderNotes,
    OrderExtraService
  },
  data() {
    return {
      isLoading: true,
    }
  },
  watch: {
    "$route":"fetchOrder"
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    activeUser(){
      return this.$store.state.activeUser
    },
    product(){
      return this.order.cart.product
    },
    activeTab(){
      return this.$route.query.tab ? +this.$route.query.tab : 2;
    },
    isNotifyApproval(){
      return (this.order.status == 'pending') && (this.order.has_paid == 0)
    }
  },
  methods:{
    deleteDraft() {
      Swal.fire({
        title: "Are you sure?",
        text: "You are about to delete this order",
        reverseButtons:true,
        showCancelButton: true,
        confirmButtonColor: "#f1556c",
        cancelButtonColor: "#111",
        confirmButtonText: "Delete",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/orders/${this.order.id}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$router.push({path:'/orders'})
            }
          })
        }
      });
    },
    fetchOrder(){
      this.isLoading = true
      this.$store.dispatch("orderList/fetchOrder",this.$route.params.orderId)
      .then((response) => {
        this.isLoading = false
        if(!response.data.success){
          this.$router.push({path: '/error-404'})
        } 
      })
    },
  },
  created(){
    this.fetchOrder()
  },

}
</script>

<style lang="scss">
.order-show .soft-bread{
  font-weight: 600;
  background: white
}

.order-show .soft-bread .soft-bread-item{
    display:inline-block;
    color:black;
    padding: 0.2rem 0.8rem;
}

</style>
