<template>
  <div class="delivery-show pb-4">
    <div v-if="fetchingDelivery">
        <b-skeleton animation="wave" height="400px" width="100%"></b-skeleton>
    </div>
    <div v-else>
        <div v-if="!delivery">No delivery found</div>
        <div v-else class="row gy-4">
            <div class="col-lg-7">
                <div class="mb-2" v-if="canTakeAction" style="line-height:2.5">
                    <b-button @click="updateDelivery('FINALIST')" class="mr-3" size="sm" variant="outline-primary">
                        <i class="mdi mdi-check"></i> Select as finalist</b-button>
                    <b-button @click="updateDelivery('ELIMINATED')" class="mr-3" size="sm" variant="outline-primary">
                        <i class="mdi mdi-close"></i> Eliminate designer</b-button>
                    <b-button @click="updateDelivery('BLOCKED')" class="mr-3" size="sm" variant="outline-primary">
                        <i class="mdi mdi-face"></i> Block Designer</b-button>
                </div>
                <div class="mb-2" v-if="canChooseWinner">
                    <b-button @click="updateDelivery('WINNER')" class="mr-3" size="sm" variant="outline-primary">
                        <i class="mdi mdi-check"></i> Select as winner</b-button>
                </div>
                <div class="">
                    <delivery-files column-class="col-lg-4" :files="delivery.files" />
                </div>
                <div class="text-muted border-bottom pb-2 my-2 font-weight-bold d-flex justify-content-between">
                    <span>{{delivery.created_at | date_ago}}</span>
                </div>
                <div v-if="canShowReviewForm">
                    <order-review-form  :delivery-id="delivery.id"/>
                </div>
                <div v-if="delivery.is_reviewed">
                    <ul class="list-inline mb-0">
                        <li class="list-inline-item mr-1" v-for="star in parseInt(delivery.review.rating)" :key="'0-'+star">
                            <img :src="absoluteUrl('/images/svg/star.svg')" alt="Review rating" width="12"></li>
                        <li class="list-inline-item mr-1" v-for="nostar in  ((delivery.review.rating % 1 == 0 ? 5 : 4) - parseInt(delivery.review.rating))" :key="nostar">
                            <img :src="absoluteUrl('/images/svg/star-muted.svg')" alt="Review rating" width="12"></li>
                    </ul>
                    <p class="mb-0 text-muted">{{delivery.review.content}}</p>
                </div>
            </div>
            <div class="col-lg-5">
                <delivery-messages />
            </div>
        </div>
    </div>
  </div>
</template>
<script>

import OrderReviewForm from "./OrderReviewForm.vue"
import DeliveryMessages from "./DeliveryMessages.vue"
import DeliveryFiles from "./DeliveryFiles.vue"

export default {
    components: {
        OrderReviewForm,
        DeliveryMessages,
        DeliveryFiles,
    },
    props:{
        deliveryId:{
            type: Number,
            default: null
        }
    },
    data(){
        return {
            fetchingDelivery: true,
        }
    },
    computed: {
        order(){
            return this.$store.state.orderList.order
        },
        delivery(){
            return this.$store.state.orderList.delivery
        },
        canTakeAction(){
            return !["FINALIST","ELIMINATED","BLOCKED"].includes(this.delivery.status) && this.order.stage != 'finished'
        },
        canChooseWinner(){
            return ["FINALIST"].includes(this.delivery.status) && this.order.stage != 'finished'
        },
        canShowReviewForm(){
            return !this.delivery.is_reviewed && this.delivery.status == "WINNER"
        }
    },
    methods:{
        updateDelivery(action){
            const formData = new FormData()
            formData.append('data', JSON.stringify({action: action}))
            Swal.fire({
                title: "Are you sure?",
                text: `You are about to ${action} this desiger!`,
                reverseButtons:true,
                showCancelButton: true,
                confirmButtonColor: "#28a745",
                cancelButtonColor: "#323a46",
                confirmButtonText: "Proceed!",
            }).then((result) => {
                if (result.value) {
                    this.$store.dispatch("changeLoaderValue",true)
                    this.$http.post(`/deliveries/${this.delivery.id}/update`,formData)
                        .then((response) => {
                        this.$store.dispatch("changeLoaderValue")
                        if(response.data.success){
                            this.$store.commit("orderList/UPDATE_DELIVERY", response.data.data.delivery)
                            this.$store.dispatch("orderList/fetchOrder", this.order.id)
                        }
                    })
                }
            });
        },
    },
    mounted(){
        this.$http.get(`/deliveries/${this.deliveryId}/show`)
            .then((response) => {
            this.fetchingDelivery = false
            if(response.data.success){
                this.$store.commit("orderList/SET_DELIVERY", response.data.data.delivery)
            }
        })
    }
}
</script>
